import React, { FC } from 'react';
import './ButtonIcon.scss';
import { IButtonIconProps } from '../../../types/types';

const ButtonIcon: FC<IButtonIconProps> = (props) => {
    return (
        <img
            data-testid="button__icon"
            src={props.icon}
            className={`button__icon button__icon--${props.type}`}
            alt={props.desc}
        />
    );
};

export default ButtonIcon;
