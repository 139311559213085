import React, { useState, FC } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { FaTimes } from 'react-icons/fa';
import { HiExternalLink } from 'react-icons/hi';

import Button from '../Button';

import logo from '../../../assets/img/logo.svg';
import arrowRight from '../../../assets/img/arr-right.svg';

import './Header.scss';

const Header: FC = () => {
    const [buttonIcon, setButtonIcon] = useState(true);
    const changeButtonIcon = () => setButtonIcon(!buttonIcon);

    return (
        <header data-testid="header" className="header">
            <div className="header__wrapper">
                <Link to="/" className="header__logo--link">
                    <img className="header__logo" src={logo} alt="Blackbird Lab" />
                </Link>
                <nav className="header__nav">
                    <Link
                        className="link menu-link"
                        partiallyActive={true}
                        activeClassName="active__border active__border--style"
                        to="/what-we-do/"
                    >
                        <span className="header__nav-text">What We Do</span>
                    </Link>
                    <Link
                        className="link menu-link"
                        partiallyActive={true}
                        activeClassName="active__border active__border--style"
                        to="/how-we-work/"
                    >
                        <span className="header__nav-text">How We Work</span>
                    </Link>
                    <a
                        href="https://careers.blackbird-lab.com/en-GB"
                        target="_blank"
                        className="link menu-link"
                        rel="noreferrer"
                    >
                        <span className="header__nav-text">
                            Careers
                            <HiExternalLink
                                style={{
                                    position: 'relative',
                                    top: '3px',
                                    left: '2px',
                                    fontSize: '16px',
                                }}
                            />
                        </span>
                    </a>
                    <Link
                        className="link menu-link"
                        partiallyActive={true}
                        activeClassName="active__border active__border--style"
                        to="/blog/"
                    >
                        <span className="header__nav-text">Blog</span>
                    </Link>
                    <Link
                        className="link menu-link"
                        partiallyActive={true}
                        activeClassName="active__border active__border--style"
                        to="/about-us/"
                    >
                        <span className="header__nav-text">About Us</span>
                    </Link>
                    <Link className="link menu-link" to="/contact-us">
                        <Button
                            icon={arrowRight}
                            type="secondary"
                            className="button"
                            desc="Contact us"
                            mobile="mobile"
                        >
                            Contact us
                        </Button>
                    </Link>
                </nav>
                <div className="header__nav--mobile-wrapper">
                    {!buttonIcon && (
                        <nav className="header__nav--mobile">
                            <h5 className="header__nav--mobile-title">Menu</h5>
                            <Link
                                className="link link--mb"
                                partiallyActive={true}
                                activeClassName="active__border active__border--style"
                                to="/what-we-do/"
                            >
                                <span className="header__nav-text">What We Do</span>
                            </Link>
                            <Link
                                className="link link--mb"
                                partiallyActive={true}
                                activeClassName="active__border active__border--style"
                                to="/how-we-work/"
                            >
                                <span className="header__nav-text">How We Work</span>
                            </Link>
                            <a
                                href="https://careers.blackbird-lab.com/en-GB"
                                target="_blank"
                                className="link link--mb"
                                rel="noreferrer"
                            >
                                <span className="header__nav-text">
                                    Careers
                                    <HiExternalLink
                                        style={{
                                            position: 'relative',
                                            top: '2px',
                                            left: '2px',
                                            fontSize: '18px',
                                        }}
                                    />
                                </span>
                            </a>
                            <Link
                                className="link link--mb"
                                partiallyActive={true}
                                activeClassName="active__border active__border--style"
                                to="/blog/"
                            >
                                <span className="header__nav-text">Blog</span>
                            </Link>
                            <Link
                                className="link link--mb"
                                partiallyActive={true}
                                activeClassName="active__border active__border--style"
                                to="/about-us/"
                            >
                                <span className="header__nav-text">About Us</span>
                            </Link>
                            <h5 className="header__nav--mobile-title">Need a help?</h5>
                            <Link
                                className="link link--mb"
                                to="/contact-us"
                                partiallyActive={true}
                                activeClassName="active__border active__border--style"
                            >
                                <span className="header__nav-text">Contact us</span>
                            </Link>
                        </nav>
                    )}

                    <button
                        onClick={() => changeButtonIcon()}
                        className={classNames('header__nav-menu', {
                            'header__nav-menu--active': true,
                        })}
                    >
                        {buttonIcon ? (
                            <>
                                <div className="header__nav-bars" />
                                <div className="header__nav-bars" />
                                <div className="header__nav-bars" />
                            </>
                        ) : (
                            <FaTimes
                                className="header__nav-times"
                                style={{
                                    color: 'black',
                                }}
                            />
                        )}
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
