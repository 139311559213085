import React, { FC } from 'react';
import './Button.scss';
import ButtonIcon from '../ButtonIcon';
import { IButtonProps } from '../../../types/types';

const Button: FC<IButtonProps> = (props) => {
    return (
        <button
            data-testid="button"
            className={`button button--${props.type} button--${props.mobile} button--${props.subtype}`}
        >
            {props.children}
            <ButtonIcon type={props.iconType} icon={props.icon} desc={props.desc} />
        </button>
    );
};

export default Button;
